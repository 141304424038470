const mobileMenu = () => {

  function openOverlayMenu() {
    let burgerMenu = document.getElementById('mobile-burger');
    burgerMenu.addEventListener('click', event => {
      document.getElementById("mobile-menu").style.height = "100%";
    });
  };

  function closeOverlayMenu() {
    let closeBtn = document.getElementById('close-btn');
    closeBtn.addEventListener('click', event => {
      document.getElementById("mobile-menu").style.height = "0%";
    });
  };

  openOverlayMenu();
  closeOverlayMenu();

};

export { mobileMenu };
