// navbar.js
const navbar = () => {

  function scrollNavbar() {
    let windowTop = pageYOffset;
    let navbar = document.getElementById('navbar-container');
    if (windowTop >= 90) {
      navbar.classList.add('scrolled-navbar');
    } else {
      navbar.classList.remove('scrolled-navbar');
    };
  };

  scrollNavbar();
  window.addEventListener('scroll', event => scrollNavbar());

};

export { navbar };
